// App.js
import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import Community from './pages/community/Community';
import './App.css';
import Enterprise from './pages/enterprise/Enterprise';
import Researchers from './pages/researchers/Researchers';
import Spacer from './components/spacer/Spacer';
import Privacypolicy from './pages/subpage/Privacypolicy';
import TermsandConditions from './pages/subpage/Termsandconditions';

function App() {

  /************************************DISABLE RIGHT CLICK CODE********************************************** */
  // useEffect(() => {
  //   function handleContextMenu(e) {
  //     e.preventDefault();
  //   }

  //   document.addEventListener('contextmenu', handleContextMenu);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);
  /************************************************************************************ */

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content-container">
          <Routes>
            <Route path="/enterprise" element={<Enterprise />} />
            <Route path="/researchers" element={<Researchers />} />
            <Route path="/community" element={<Community />} />
            <Route path="/" exact element={<Home />} />

            <Route path="/about-us" element={<Enterprise />} />
            <Route path="/our-customers" element={<Enterprise />} />
            <Route path="/careers" element={<Enterprise />} />
            <Route path="/partners" element={<Enterprise />} />
            <Route path="/contact-us" element={<Enterprise />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="/terms-and-conditions" element={<TermsandConditions />} />
            <Route path="/security" element={<Enterprise />} />
            <Route path="/resource-library" element={<Enterprise />} />
            <Route path="/blog" element={<Enterprise />} />
            <Route path="/support" element={<Enterprise />} />
            <Route path="/researchers/programs" element={<Enterprise />} />
            <Route path="/researchers/bug-bounty-list" element={<Enterprise />} />
            <Route path="/researchers/leaderboard" element={<Enterprise />} />
            <Route path="/products/penetration-testing" element={<Enterprise />} />
            <Route path="/products/pen-test-as-a-service" element={<Enterprise />} />
            <Route path="/products/application-pen-test" element={<Enterprise />} />
          </Routes>
        </div>
        <Spacer />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
