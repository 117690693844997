// SubNavbar.jsx
import React, { useState } from 'react';
import Programs from '../navtavcontent/Programs';
import Scoring from '../navtavcontent/Scoring';
import Leaderboard from '../navtavcontent/Leaderboard';
import Message from '../navtavcontent/Message';
import Reports from '../navtavcontent/Reports';
import './subnavbar.css';

const SubNavbar = () => {
  const [selectedItem, setSelectedItem] = useState('Sub Item 1');

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  let content;
  if (selectedItem === 'Sub Item 1') {
    content = <Programs />;
  } else if (selectedItem === 'Sub Item 2') {
    content = <Scoring />;
  } else if (selectedItem === 'Sub Item 3') {
    content = <Leaderboard />;
  } else if (selectedItem === 'Sub Item 4') {
    content = <Message />;
  } else if (selectedItem === 'Sub Item 5') {
    content = <Reports />;
  } else {
    content = <Programs />;
  }

  return (
    <div className="sub-navbar">
      <div className="navtabWrapper">
        <ul className="navtabcontainer">
          <li className="navtablink">
            <button className={selectedItem === 'Sub Item 1' ? 'active' : ''} onClick={() => handleItemClick('Sub Item 1')}>
              Programs
            </button>
          </li>
          <li className="navtablink">
            <button className={selectedItem === 'Sub Item 2' ? 'active' : ''} onClick={() => handleItemClick('Sub Item 2')}>
              Scoring
            </button>
          </li>
          <li className="navtablink">
            <button className={selectedItem === 'Sub Item 3' ? 'active' : ''} onClick={() => handleItemClick('Sub Item 3')}>
              Leaderboard
            </button>
          </li>
          <li className="navtablink">
            <button className={selectedItem === 'Sub Item 4' ? 'active' : ''} onClick={() => handleItemClick('Sub Item 4')}>
              Message
            </button>
          </li>
          <li className="navtablink">
            <button className={selectedItem === 'Sub Item 5' ? 'active' : ''} onClick={() => handleItemClick('Sub Item 5')}>
              Reports
            </button>
          </li>
        </ul>
      </div>
      {content}
    </div>
  );
};

export default SubNavbar;
