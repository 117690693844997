import React from 'react'
import './support.css'
import support from '../../public/support.png'

export default function Support() {
    return (
        <div className='sectionWrapper'>
            <div className='supportBox'>
                <div className="supportWrapper">
                    <div className="supportTextContainer">
                        <div className="supportHeadings">
                            <div className="supportTopHeading">#1 Support in SECURITY</div>
                            <div className="supportHeadingWrapper">
                                <div className="supportHeading">24/7 real-time
                                    support.</div>
                            </div>
                        </div>
                        <div className="supportDescWrapper">
                            <div className="supportDesc">Safehats has the highest rated client support in security.
                                We're here 24 hours a day, every day of the week, including
                                holidays.</div>
                        </div>
                    </div>
                    <img src={support} alt="" className="supportImage" />
                </div>
            </div>
        </div>
    )
}
