import React from 'react';
import { Link } from 'react-router-dom';
import './infosection.css';
import tigerteam from '../../public/tigerteam.png'

function Safehatstigerteam() {
    return (
        <div className="sectionWrapper">
            <div className="whiteBox">
                <div className="infoTextContainerTeam">
                    <div className="infoHeadingContainer">
                        <div className="infoHeading">
                            <span>The Safehats</span>{' '}
                            <span style={{ color: '#ED765E' }}>Tiger Team</span>
                        </div>
                        <div className="infoSubHeading">
                            <span>Our</span>{' '}
                            <span style={{ color: '#ED765E' }}>Elite Squad</span>{' '}
                            <span>of Security Researchers</span>
                        </div>
                    </div>
                    <div className="infoDescriptionContainer" >
                        <div className="infoDescription" >The SafeHats Tiger Team is a group of curated security researchers and hackers. They are selected
                            based on their skills, previous work, accomplishments, and a variety of other factors.
                            We are proud to partner with such talented individuals.
                            <br />
                        </div>
                    </div>
                    <Link to="https://app.safehats.com/" target="_blank" rel="noopener noreferrer" className="infoButtonContainer" >
                        <div className="infoButton" >
                            <Link to="https://app.safehats.com/signup" target="_blank" rel="noopener noreferrer" className="infoButtonText" >START HACKING</Link>
                        </div>
                    </Link>
                </div>
                <div className="infoImageContainerTeam">
                    <img className="infoImage" src={tigerteam} alt="Logo" />
                </div>
            </div>
        </div>
    );
}

export default Safehatstigerteam; 