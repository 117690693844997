import React from 'react';
import { Link } from 'react-router-dom';
import './infosection.css';
import safehatstiger from '../../public/safehatstiger.png'

function SafehatsTiger() {
    return (
        <div className="sectionWrapper">
            <div className="whiteBox">
                <div className="infoTextContainer">
                    <div className="infoHeadingCotainer">
                        <div className="infoHeading">
                            <span>Are you a</span>{' '}
                            <span style={{ color: '#ED765E' }}>Tiger?</span>
                        </div>
                    </div>
                    <div className="infoDescriptionContainer" >
                        <div className="infoDescription" >At SafeHats it is our top priority to ensure that we bring  fourth the best and trusted
                            minds in the world, so that the collective brain power can be leveraged to find
                            the most critical and high impact vulnerabilities. Our Tiger Team applicants are screened
                            through behavioral and professional interviews and skill tests.</div>
                    </div>
                    <Link to="https://app.safehats.com/" target="_blank" rel="noopener noreferrer" className="infoButtonContainer" >
                        <div className="infoButton" >
                            <Link to="https://app.safehats.com/signup" target="_blank" rel="noopener noreferrer"  className="infoButtonText" >START HACKING</Link>
                        </div>
                    </Link>
                </div>
                <div className="infoImageContainer">
                    <img className="infoImage" src={safehatstiger} alt="Logo" />
                </div>
            </div>
        </div>
    );
}

export default SafehatsTiger; 