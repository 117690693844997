import React from 'react'
import { Link } from 'react-router-dom'
import './communitysection.css'
import greenarrow from '../../public/greenarrow.svg'
import skills from '../../public/skills.png'

export default function Communitysection() {
    return (
        <div className='sectionWrapper'>
            <div className='communityBox'>
                <div className='communityContainer' >
                    <div className="ctextContainer">
                        <div className="chero">
                            <div className="cheroHeadings">
                                <div className="cheroTopHeading">COMMUNITY</div>
                                <div className="cheroHeadingWrapper">
                                    <div className="cheroHeading">Upskill, Upgrade and Update with Safehats</div>
                                </div>
                            </div>
                            <div className="cheroDescWrapper">
                                <div className="cheroDesc">Dynamic learning environment designed to teach students how to
                                    discover and address security bugs effectively. Through hands-on
                                    practice, aspiring cybersecurity professionals gain valuable skills in
                                    identifying vulnerabilities, enhancing their real-world readiness.</div>
                            </div>
                        </div>
                        <div className="citemList">
                            <div className="citemListItem">
                                <div className="citemIcon"></div>
                                <div className="citemTextWrapper">
                                    <div className="citemText">Bug Detection</div>
                                </div>
                            </div>
                            <div className="citemListItem">
                                <div className="citemIcon"></div>
                                <div className="citemTextWrapper">
                                    <div className="citemText">Vulnerability Analysis</div>
                                </div>
                            </div>
                            <div className="citemListItem">
                                <div className="citemIcon"></div>
                                <div className="citemTextWrapper">
                                    <div className="citemText">Risk Assessment</div>
                                </div>
                            </div>
                            <div className="citemListItem">
                                <div className="citemIcon"></div>
                                <div className="citemTextWrapper">
                                    <div className="citemText">Security Testing</div>
                                </div>
                            </div>
                            <div className="citemListItem">
                                <div className="citemIcon"></div>
                                <div className="citemTextWrapper">
                                    <div className="citemText">Compliance Audit</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cimageContainer">
                        <img src={skills} alt="" className="cimage" />
                    </div>
                </div>
                <Link to="https://app.safehats.com/company-signup" target="_blank" rel="noopener noreferrer" className='cbuttonContainer' >
                    <div className="buttonWrapper">
                        <div className="buttonText">Test Your Program Now</div>
                        <img src={greenarrow} alt="" className="buttonSVG" />
                    </div>
                </Link>
            </div>
        </div>
    )
}
