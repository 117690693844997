import React from 'react';
import './listdesc.css';
import shicon7 from '../../public/shicon7.png'
import shicon8 from '../../public/shicon8.png'
import shicon9 from '../../public/shicon9.png'
import shicon10 from '../../public/shicon10.png'
import shicon11 from '../../public/shicon11.png'
import shicon12 from '../../public/shicon12.png'

function Researchinit() {
    return (
        <div className="sectionWrapper">
            <div className="columnwhiteBox">
                <div className="ldheadingContainer">
                    <div className="ldheading">What's in it for me?</div>
                </div>
                <div className="ldlistContainer">
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon7} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Leverage Your
                                Unique Skill</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Find a critical bug in enterprise-grade security systems and gain lucrative rewards</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon8} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">At the Forefront of
                                Technology</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Hands-on experience with the latest and greatest tech in the market</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon9} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Bragging Rights
                            </div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Gain swag within the community, and become a sought-after security researcher</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon10} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Access to Private
                                Programs</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Get early access to some of the exclusive private programs</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon11} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Access to Triaging
                                Team</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Opportunity to be part of the prestigious SafeHats Triaging team</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon12} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">SafeHats Tiger Badge</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Get SafeHats exclusive merit badge on your profile picture</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Researchinit; 