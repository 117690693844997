import React from 'react';
import Communitypartner from '../../components/listdesc/Communitypartner';
import Safehatssolution from '../../components/listdesc/SafehatsSolution';
import Whycommunity from '../../components/doublebox/Whycommunity';
import HerosectionCommunity from '../../components/herosection/Herosectioncommunity';

function Community() {
  return (
    <div>
      <>
        <HerosectionCommunity />
        <Communitypartner />
        <Safehatssolution />
        <Whycommunity />
      </>
    </div>
  );
}

export default Community;
