import React from 'react';
import './spacer.css';

function Spacer() {
    return (
        <div className="sectionWrapper">
            <br />
            <br />
        </div>
    );
}

export default Spacer; 