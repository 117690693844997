import React from 'react'
import { Link } from 'react-router-dom'
import './doublebox.css'
import greenarrow from '../../public/greenarrow.svg'
import pbicon3 from '../../public/pbicon3.png'
import pbicon2 from '../../public/pbicon2.png'

export default function Doublebox() {
    return (
        <div className='sectionWrapper'>
            <div className='communityBox'>
                <div className="doubleBoxWrapper">
                    <div className="doubleBoxHeadingContainer">
                        <div className="doubleBoxHeadingWrapper">
                            <div className="doubleBoxMainHeading">
                                Safehats Hacker Etiquette</div>
                            <div className="doubleboxSubHeading">We are working together for a common goal: better information security and a safer Internet for us all.
                                To that end, we have a list of guidelines for our partners. Safehats undertakes to protect the interests of all t
                                he security researchers and hackers in our community, so help us help you.</div>
                        </div>
                    </div>
                    <div className="doubleBoxContentContainer">
                        <div className="boxOne">
                            <div className="boxHeadingWrapper">
                                <div className="boxHeadingContainer">
                                    <div className="boxHeading">DO'S</div>
                                </div>
                            </div>
                            <div className="boxList">
                                <div className="boxItem">
                                    <img src={pbicon3} alt="" className="boxItemImage" />
                                    <div className="boxItemText">
                                        <div className="greenHover">
                                            Stick to the standard reporting structure. Once the bug is
                                            reported to us, we will confirm receipt in 72 hours. Thereafter
                                            , we need about 3 to 10 days for verification and validation.
                                        </div>
                                    </div>
                                </div>
                                <div className="boxItem">
                                    <img src={pbicon3} alt="" className="boxItemImage" />
                                    <div className="boxItemText">
                                        <div className="greenHover">
                                            Adhere to the scope of the program.
                                        </div>
                                    </div>
                                </div>
                                <div className="boxItem">
                                    <img src={pbicon3} alt="" className="boxItemImage" />
                                    <div className="boxItemText">
                                        <div className="greenHover">
                                            Use only test accounts to discover vulnerabilities, and not live accounts, as this may disrupt service.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="boxOne">
                            <div className="boxHeadingWrapper">
                                <div className="boxHeadingContainer">
                                    <div className="boxHeadingTwo">DON'TS</div>
                                </div>
                            </div>
                            <div className="boxList">
                                <div className="boxItem">
                                    <img src={pbicon2} alt="" className="boxItemImage" />
                                    <div className="boxItemText">
                                        <div className="redHover">
                                            Don’t publicise the bug before it has been resolved. We assure you that your bragging rights remain intact.
                                        </div>
                                    </div>
                                </div>
                                <div className="boxItem">
                                    <img src={pbicon2} alt="" className="boxItemImage" />
                                    <div className="boxItemText">
                                        <div className="redHover">
                                            Do not disrupt or change live user data or account information during a discovered breach.
                                        </div>
                                    </div>
                                </div>
                                <div className="boxItem">
                                    <img src={pbicon2} alt="" className="boxItemImage" />
                                    <div className="boxItemText">
                                        <div className="redHover">
                                            No automated scanners please! We want your unique expertise only.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="https://app.safehats.com/signup" target="_blank" rel="noopener noreferrer" className='cbuttonContainer'>
                    <div className="buttonWrapper">
                        <div className="buttonText">Test Your Program Now</div>
                        <img src={greenarrow} alt="" className="buttonSVG" />
                    </div>
                </Link>
            </div>
        </div>
    )
}
