import React from 'react';
import './listdesc.css';
import ldbigimage from '../../public/ldbigimage.png'

function Listimage() {
    return (
        <div className="sectionWrapper">
            <div className="columnwhiteBox">
                <div className="ldheadingContainer">
                    <div className="ldheading">MOST SECURE PLATFORM</div>
                </div>
                <div className="ldimageImageContainer">
                    <img src={ldbigimage} alt="" className="ldimageImage" />
                </div>
            </div>
        </div>
    );
}

export default Listimage; 