import React from 'react'
import { Link } from 'react-router-dom'
import './doublebox.css'
import greenarrow from '../../public/greenarrow.svg'

export default function Whycommunity() {
    return (
        <div className='sectionWrapper'>
            <div className='communityBox'>
                <div className="doubleBoxWrapper">
                    <div className="doubleBoxHeadingContainer">
                        <div className="doubleBoxHeadingWrapper">
                            <div className="doubleBoxMainHeading">
                                Why to be part of Safehats<br /> Community ?
                            </div>
                            <div className="doubleboxSubHeading">Safehats community is made for hackers, developers, enterprises, and others to engage with each other over discussion forums, share opportunities, share knowledge, get access to various online resources, and more.
                                <br />
                                <br />
                                Networking is fun and what could be best way to connect using Safehats community where you have opportunity to interact with more than 1000s of Hackers, developers, experts, Industry leaders, enterprises, and more.
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="https://app.safehats.com/" target="_blank" rel="noopener noreferrer" className='cbuttonContainer'>
                    <div className="buttonWrapper">
                        <div className="buttonText">Test Your Program Now</div>
                        <img src={greenarrow} alt="" className="buttonSVG" />
                    </div>
                </Link>
            </div>
        </div>
    )
}
