import React from 'react'
import './navtab.css'
import SubNavbar from '../subnavbar/SubNavbar'

export default function Navtab() {
    return (
        <div className='sectionWrapper'>
            <div className='navtabBox'>
                <div className="navtabContainer">
                    <div className="navtabHeadings">
                        <div className="navtabSubHeading">Built for RESEARCHERS</div>
                        <div className="navtabHeading">Enhance your skills and get rewarded</div>
                    </div>
                    <div className="navtabComponent">
                        <SubNavbar />
                    </div>
                </div>
            </div>
        </div>
    )
}
