// Home.js
import React from 'react';
import Herosection from '../../components/herosection/Herosection';
import Infosection from '../../components/infosection/Infosection';
import Pinkherosection from '../../components/pinkherosection/Pinkherosection';
import Bgimagesection from '../../components/bgimagesection/Bgimagesection';
import Communitysection from '../../components/communitysection/Communitysection';
import Support from '../../components/support/Support';
import Navtab from '../../components/navtab/Navtab';

function Home() {
  return (
    <>
    <Herosection />
    <Infosection />
    <Pinkherosection />
    <Bgimagesection />
    <Navtab />
    <Communitysection />
    <Support />
    </>
  );
}

export default Home;

