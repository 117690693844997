import React from 'react';
import Doublebox from '../../components/doublebox/Doublebox';
import KarmaScore from '../../components/doublebox/KarmaScore';
import SafehatsTiger from '../../components/infosection/SafehatsTiger';
import Safehatstigerteam from '../../components/infosection/Safehatstigerteam';
import Shortlisting from '../../components/listdesc/Shortlisting';
import Researchinit from '../../components/listdesc/Researcherinit';
import HerosectionResearcher from '../../components/herosection/Herosectionresearcher';


function Researchers() {
  return (
    <div>
      <>
        <HerosectionResearcher />
        <SafehatsTiger />
        <Shortlisting />
        <Safehatstigerteam />
        <Researchinit />
        <Doublebox />
        <KarmaScore />
      </>
    </div>
  );
}

export default Researchers;
