import React from 'react'
import { Link } from 'react-router-dom'
import './doublebox.css'
import greenarrow from '../../public/greenarrow.svg'
import pbicon3 from '../../public/pbicon3.png'
import pbicon2 from '../../public/pbicon2.png'

export default function KarmaScore() {
    return (
        <div className='sectionWrapper'>
            <div className='communityBox'>
                <div className="doubleBoxWrapper">
                    <div className="doubleBoxHeadingContainer">
                        <div className="doubleBoxHeadingWrapper">
                            <div className="doubleBoxMainHeading">
                                Introducing Karma Score</div>
                            <div className="doubleboxSubHeading">Safehats provides various scoring methodologies that security researchers earn either through submitting bug report
                                or by winning bounty amount. Karma score is the aggregate score that signifies the reputation of security researchers.
                                Along Karma score, we also introduce Rank, Acceptance rate and Average bug severity.
                                Invitation to private programs will be based on these scores. </div>
                        </div>
                    </div>
                    <div className="doubleBoxContentWrapper">
                        <div className="doubleBoxContentMainHeadingContainer">
                            <div className="boxHeading">How Scoring works?</div>
                        </div>
                        <div className="doubleBoxContentContainer">
                            <div className="boxOne">
                                <div className="boxHeadingWrapper">
                                    <div className="boxHeadingContainer">
                                        <div className="boxHeadingKarma">100 karma score is assigned to the hacker once he/she signs up in the platform</div>
                                    </div>
                                </div>
                                <div className="boxListKarma">
                                    <div className="boxItemKarma">
                                        <img src={pbicon3} alt="" className="boxItemImage" />
                                        <div className="boxItemText">Resolved= +10 </div>
                                    </div>
                                    <div className="boxItemKarma">
                                        <img src={pbicon3} alt="" className="boxItemImage" />
                                        <div className="boxItemText">Duplicate of Resolved bug= +3
                                        </div>
                                    </div>
                                    <div className="boxItemKarma">
                                        <img src={pbicon3} alt="" className="boxItemImage" />
                                        <div className="boxItemText">Not Acceptable(N/A)=  -5
                                        </div>
                                    </div>
                                    <div className="boxItemKarma">
                                        <img src={pbicon3} alt="" className="boxItemImage" />
                                        <div className="boxItemText">Duplicate of N/A= -5
                                        </div>
                                    </div>
                                    <div className="boxItemKarma">
                                        <img src={pbicon3} alt="" className="boxItemImage" />
                                        <div className="boxItemText">SPAM= -10
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="boxOne">
                                <div className="boxHeadingWrapper">
                                    <div className="boxHeadingContainer">
                                        <div className="boxHeadingKarma">If program is giving bounty and researchers get Bounty amount</div>
                                    </div>
                                </div>
                                <div className="boxListKarma">
                                    <div className="boxItemKarma">
                                        <img src={pbicon2} alt="" className="boxItemImage" />
                                        <div className="boxItemText">For Min Bounty =+10 </div>
                                    </div>
                                    <div className="boxItemKarma">
                                        <img src={pbicon2} alt="" className="boxItemImage" />
                                        <div className="boxItemText">2x of Min Bounty  =+152x of Min Bounty  =+15
                                        </div>
                                    </div>
                                    <div className="boxItemKarma">
                                        <img src={pbicon2} alt="" className="boxItemImage" />
                                        <div className="boxItemText">4x of Min Bounty= +20
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="https://app.safehats.com/signup" target="_blank" rel="noopener noreferrer" className='cbuttonContainer'>
                    <div className="buttonWrapper">
                        <div className="buttonText">Test Your Program Now</div>
                        <img src={greenarrow} alt="" className="buttonSVG" />
                    </div>
                </Link>
            </div>
        </div>
    )
}
