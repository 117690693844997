import React from 'react';
import { Link } from 'react-router-dom';
import './bgimagesection.css';
import bgImage from '../../public/bgimage.png';
import arrow from '../../public/ArrowVector.svg';
import docs from '../../public/docs.png';

export default function BgImagesection() {
    const isMobile = window.innerWidth <= 768;
    const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;

    return (
        <div className="sectionWrapper">
            <div
                className="bgBox"
                style={{
                    backgroundImage: isMobile || isTablet ? 'none' : `url(${bgImage})`,
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="contentContainerBgbox">
                    <div
                        className="contentWrapper"
                    >
                        <div
                            className="headingContainer"
                        >
                            <div
                                className="headingbg"
                            >
                                Discover critical vulnerabilities in your application now!!
                            </div>
                        </div>
                    </div>
                    <img
                        src={docs}
                        alt=""
                        className="imageContainer"
                    />
                </div>
                <Link
                    to="https://app.safehats.com/company-signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="buttonContainer"
                >
                    <div
                        className="buttonWrapper"
                    >
                        <div
                            className="buttonText"
                        >
                            Test Your Program Now
                        </div>
                        <img
                            src={arrow}
                            alt=""
                            className="buttonSVG"
                        />
                    </div>
                </Link>
            </div>
        </div>
    );
}
