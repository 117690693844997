import React from 'react';
import './listdesc.css';
import shicon7 from '../../public/shicon7.png'
import shicon8 from '../../public/shicon8.png'
import shicon9 from '../../public/shicon9.png'

function Safehatssolution() {
    return (
        <div className="sectionWrapper">
            <div className="columnwhiteBox">
                <div className="ldheadingContainer">
                    <div className="ldheading">SAFEHATS SOLUTION</div>
                </div>
                <div className="ldlistContainer">
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon7} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">PEOPLE</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Safehats platform provides highly experienced, diverse skills set, geographical spread  curious  bunch of security researchers who are ready to help enterprises to create secure applications.</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon8} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">PROCESS</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Safehats mission operation has streamlined various process from disclosure policy, triaging, vulnerability report submission, Hacker engagement, customer engagement, and various others to deliver quality service.</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon9} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">TECHNOLOGY
                            </div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Safehats launchpad platform is the enabler of our vulnerability rewards program. It enables enterprises connect with hackers, manage secure report submissions, remediation, and more.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Safehatssolution; 