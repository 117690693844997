import React from 'react'
import './pinkherosection.css'
import pbicon1 from '../../public/pbicon1.png'
import pbicon2 from '../../public/pbicon2.png'
import pbicon3 from '../../public/pbicon3.png'
import pinkImage from '../../public/pinkImage.png'

export default function Pinkenterprise() {
    return (
        <div className='sectionWrapper'>
            <div className='pinkBox'>
                <div className='contentContainer'>
                    <div className="row">
                        <div className="subHeading">
                            <div className="subHeadingText">JOIN US</div>
                        </div>
                        <div className="heading">
                            <div className="headingText">WHAT’S IN IT FOR ME?</div>
                        </div>
                    </div>
                    <div className="rowBottom">
                        <div className="textColumn">
                            <div className="listContainer">
                                <div className="listItem">
                                    <img src={pbicon1} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading">Customize your Security
                                            Program</div>
                                        <div className="listBody">Running a bug bounty program for the first time? a public
                                            program opens it up to our full network of hackers to
                                            submit bug reports, and is a great way to scale up your
                                            security operation. greatest tech in the market. Hands-on
                                            experience with the latest Test the waters with
                                            a private program, and invite only the security researchers
                                            you want for penetration testing. </div>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <img src={pbicon2} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading">Full-featured Enterprise
                                            Dashboard Rights
                                        </div>
                                        <div className="listBody">Instant overviews of your programs, bugs reported,
                                            hackers, swag counts, and bounty amounts. Get deep
                                            insights at a touch, with data visualization and actionable tasks.</div>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <img src={pbicon2} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading">Detailed Researcher Profiles</div>
                                        <div className="listBody">A full list of available hackers, with detailed stats about
                                            their previous work. Cherry pick and invite to your private
                                            programs based on metrics: karma points, ranking, bug
                                            resolution history, and much more.</div>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <img src={pbicon3} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading">Dedicated Bug Report Page</div>
                                        <div className="listBody">Reward the hackers and keep them Analyse your bug
                                            reports in one place with laser focus. Check out submitted
                                            bugs, and assess their criticality, current status, and submitter
                                            information. to carry on the good work and keep looking for more bugs.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="imageColumn">
                            <img src={pinkImage} alt="" className="pinkBoxImage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
