import React from 'react'
import './ninesteps.css'

export default function Ninesteps() {
    return (
        <div className='sectionWrapper'>
            <div className="ninestepsHeadingContainer">
                <div className="ninestepsHeading">
                    <div className="ninestepsHeadingone">SECURE YOUR PROGRAM </div>
                    <div className="ninestepsHeadingtwo">IN 9 SIMPLE STEPS</div>
                </div>
            </div>
            <div className="ninestepsRow">
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">1</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">SET GOALS AND
                            EXPECTATIONS</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">Set clear goals for next 3-6 months,
                                what security maturity level you want
                                to achieve for your application</div>
                        </div>
                    </div>
                </div>
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">2</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">SET A BUDGET</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">Allocate a separate budget for
                                bug bounty to meet its desired objective</div>
                        </div>
                    </div>
                </div>
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">3</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">KEEP INTERNAL
                            TEAM READY</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">Communicate your security team and developers. Define your
                                internal SLAs to handle bug reports</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ninestepsRow">
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">4</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">CREATE YOUR SECURITY
                            PAGE AND
                            DISCLOSURE POLICY</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">Security page is the first
                                gateway for security researchers
                                to reach you and report</div>
                        </div>
                    </div>
                </div>
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">5</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">SET GOALS AND
                            EXPECTATIONS</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">Define the scope of application
                                (Web, Mobile App), out of scope
                                vulnerabiliites, Rewards</div>
                        </div>
                    </div>
                </div>
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">6</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">LAUNCH A PROGRAM</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">Launch either a private or public program based on your security maturity level.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ninestepsRow">
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">7</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">EFFECTIVELY COMMUNICATE</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">The key of success is to respond timely
                                and clear communication between
                                developers and researchers</div>
                        </div>
                    </div>
                </div>
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">8</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">REWARD RESEARCHERS</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">Reward researchers based on the severity. keep researchers motivated with market standars payout</div>
                        </div>
                    </div>
                </div>
                <div className="stepContainer">
                    {/* <div className="stepCircle">
                        <div className="stepNumber">9</div>
                    </div> */}
                    <div className="steptextContainer">
                        <div className="stepHeading">ANALYSE AND OPTIMISE</div>
                        <div className="stepDescContainer">
                            <div className="stepDesc">Analyse the result with respect to your set goals. optimize every one month, if necessary.Scale your program</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
