import React from 'react';
import './listdesc.css';
import sample1 from '../../public/sample1.png'
import sample2 from '../../public/sample2.png'
import sample3 from '../../public/sample3.png'
import sample4 from '../../public/sample4.png'
import sample5 from '../../public/sample5.png'
import sample6 from '../../public/sample6.png'

function Listdesc() {
    return (
        <div className="sectionWrapper">
            <div className="columnwhiteBox">
                <div className="ldheadingContainer">
                    <div className="ldheading">Why the best hack with us</div>
                </div>
                <div className="ldlistContainer">
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={sample1} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Flexible Programs</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Intelligently matches hackers of
                                    all skills and backgrounds to
                                    programs so everyone succeeds</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={sample2} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">The Tiger Team</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Global specialists with advanced
                                    tools validate and prioritize your
                                    vulns faster</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={sample3} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Focus on Critical
                                Vulnerabilities</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">All-in-one foundation manages
                                    your projects start-to-finish</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={sample4} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Curated Security
                                Researchers</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Enables you to hack using multi-
                                    language templates</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={sample5} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Pay-per-Bug Model</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Web, API, Mobile Apps, Cloud,
                                    IOT Devices, and more</div>
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={sample6} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Responsible Disclosure
                                Policy</div>
                            <div className="ldlistDesccontainer">
                                <div className="ldlistDesc">Learn from and work with top
                                    hackers and technologists</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Listdesc; 