import React from 'react';
import { Link } from 'react-router-dom';
import './herosection.css';
import vector2 from '../../public/Vector2SVG.svg'

function HerosectionEnterprise() {
    return (
        <div className='herosectionWrapper'>
            <div className='heroBox'>
                <div className='heroContainer'>
                    <div className='heroTextContainer'>
                        <div className='heroTextWrapper'>
                            <div className='heroText'>
                                <span >Bugs Exposed</span>{' '}
                                <span style={{ color: '#ED765E' }}>Discovering Critical Program Flaws</span>
                            </div>
                        </div>
                        <div className='heroDescContainer'>
                            <div className='heroDesc'>Leverage the power of the crowd to discover critical vulnerabilities
                                in your application, before they can be exploited</div>
                        </div>
                        <div className='heroEmailContainer'>
                            <label htmlFor="emailInput" className='heroEmail'></label>
                            <input className='heroEmail' type="email" id="emailInput" placeholder="Enter your work e-mail" />
                        </div>
                        <Link to="https://app.safehats.com/" target="_blank" rel="noopener noreferrer" className='heroButtonContainer' >
                            <div className='heroButton'>
                            <Link to="https://app.safehats.com/" target="_blank" rel="noopener noreferrer" className='herobuttontext'>TRY SAFEHATS </Link>
                            </div>
                        </Link>
                    </div>
                    <div className='heroImageContainer'>
                        <div className='heroImageWrapper'>
                            <img className='heroImage' src={vector2} alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HerosectionEnterprise; 