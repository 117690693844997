import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css'; // Create a CSS file for styling
import logo from '../../public/safehatslogoheader.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <NavLink to="/">
            <img src={logo} alt="Description" />
          </NavLink>
        </div>
        <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={handleMenuClick}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className={`links ${isOpen ? 'open' : ''}`}>
          <NavLink exact to="/" activeClassName="active" onClick={handleLinkClick}>
            Home
          </NavLink>
          <NavLink to="/enterprise" activeClassName="active" onClick={handleLinkClick}>
            Enterprises
          </NavLink>
          <NavLink to="/researchers" activeClassName="active" onClick={handleLinkClick}>
            Researchers
          </NavLink>
          <NavLink to="/community" activeClassName="active" onClick={handleLinkClick}>
            Community
          </NavLink>
          <NavLink to="https://app.safehats.com/" activeClassName="active" className="navLinkBox" target="_blank" onClick={handleLinkClick}>
            Sign-In
          </NavLink>
          <NavLink to="http://docs.safehats.com/" activeClassName="active" className="navLinkBox" target="_blank" onClick={handleLinkClick}>
            Docs
          </NavLink>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
