import React from 'react';
import Listdesc from '../../components/listdesc/Listdesc';
import Listimage from '../../components/listdesc/Listimage';
import Pinkenterprise from '../../components/pinkherosection/Pinkenterprise';
import Ninesteps from '../../components/ninesteps/Ninesteps';
import HerosectionEnterprise from '../../components/herosection/Herosectionenterprise';

function Enterprise() {
  return (
    <div>
      <>
        <HerosectionEnterprise />
        <Listdesc />
        <Listimage />
        <Pinkenterprise />
        <Ninesteps />
      </>
    </div>
  );
}

export default Enterprise;
