import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import './footer.css'
import footerlogo from '../../public/footerlogo.png'
import uptime from '../../public/uptime.svg'
import security from '../../public/security.svg'
import support from '../../public/support.svg'
import linkedinvector from '../../public/linkedinvector.svg'
import facebookvector from '../../public/facebookvector.svg'
import instagramvector from '../../public/instagramvector.svg'
import twittervector from '../../public/twittervector.svg'

export default function Footer() {
    return (
        <div className='footersectionWrapper'>
            <div className='footerWrapper'>
                <div className="footerContainer">
                    <div className="footerTop">
                        <div className="footerTopItemWrapper">
                            <div className="footerTopItem">
                                <img src={uptime} alt="" className="footerTopItemSVG" />
                                <div className="footerTopItemBody">
                                    <div className="footerTopItemBold">Free Training</div>
                                    <div className="footerTopItemNormal">& 24 hours support</div>
                                </div>
                            </div>
                        </div>
                        <div className="footerTopItemWrapper">
                            <div className="footerTopItem">
                                <img src={security} alt="" className="footerTopItemSVG" />
                                <div className="footerTopItemBody">
                                    <div className="footerTopItemNormal">Serious About</div>
                                    <div className="footerTopItemBold">Security and Privacy</div>
                                </div>
                            </div>
                        </div>
                        <div className="footerTopItemWrapper">
                            <div className="footerTopItem">
                                <img src={support} alt="" className="footerTopItemSVG" />
                                <div className="footerTopItemBody">
                                    <div className="footerTopItemBold">Highest Levels of Uptime</div>
                                    <div className="footerTopItemNormal">the last 12 months</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerList">
                        <div className="footerItem">
                            <div className="footerItemheading">ENTERPRISES</div>
                            <div className="footerItemBody">
                                <Link to="https://app.safehats.com/enterprises/product-overview" target="_blank" rel="noopener noreferrer" className="footerItemList">Overview</Link>
                                <Link to="https://app.safehats.com/enterprises/programs" target="_blank" rel="noopener noreferrer" className="footerItemList">Programs</Link>
                                <Link to="https://app.safehats.com/faqs#!#enterprises" target="_blank" rel="noopener noreferrer" className="footerItemList">FAQ</Link>
                            </div>
                        </div>
                        <div className="footerItem">
                            <div className="footerItemheading">RESEARCHERS</div>
                            <div className="footerItemBody">
                                <Link to="https://app.safehats.com/faqs#!#researchers" target="_blank" rel="noopener noreferrer" className="footerItemList">Overview</Link>
                                <div className="footerItemList">Programs</div>
                                <Link to="https://app.safehats.com/faqs#!#researchers" target="_blank" rel="noopener noreferrer" className="footerItemList">FAQ</Link>
                            </div>
                        </div>
                        <div className="footerItem">
                            <div className="footerItemheading">RESOURCES</div>
                            <div className="footerItemBody">
                                <Link to="http://docs.safehats.com/" target="_blank" rel="noopener noreferrer" className="footerItemList">Resource Library</Link>
                                <div className="footerItemList">Blog</div>
                                <Link to="https://support.instasafe.com/portal/en/kb/articles/what-is-safehats" target="_blank" rel="noopener noreferrer" className="footerItemList">Support</Link>
                            </div>
                        </div>
                        <div className="footerItem">
                            <div className="footerItemheading">ABOUT</div>
                            <div className="footerItemBody">
                                <div className="footerItemList">About Us</div>
                                <div className="footerItemList">Our Customers</div>
                                <Link to="https://app.safehats.com/contact" target="_blank" rel="noopener noreferrer" className="footerItemList">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerBottomContainer">
                    <div className="footerBottomContent">
                        <NavLink to='/'>
                            <img src={footerlogo} alt="" className="footerBottomLogo" />
                        </NavLink>
                        <div className="footerBottomContentLinks">
                            <NavLink to="/" className="footerBottomContentLinkContainer">
                                <div className="footerBottomContentLink">© 2023 safehats</div>
                            </NavLink>
                            <div className="footerBottomContentLinkContainer">
                                <div className="footerBottomContentLink">Security</div>
                            </div>
                            <NavLink to="/privacy-policy" className="footerBottomContentLinkContainer">
                                <div className="footerBottomContentLink">Your Privacy</div>
                            </NavLink>
                            <NavLink to="/terms-and-conditions" className="footerBottomContentLinkContainer">
                                <div className="footerBottomContentLink">Terms and Conditions</div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="footerBottomSocialLinks">
                        <div className="footerBottomSocialLinkContainer">
                            <Link to="https://www.linkedin.com/company/instasafe/" target="_blank" rel="noopener noreferrer" className="footerBottomSocialSVGContainer">
                                <img src={linkedinvector} alt="" className="footerBottomSocialSVG" />
                            </Link>
                        </div>
                        <div className="footerBottomSocialLinkContainer">
                            <Link to="https://www.facebook.com/InstaSafe" target="_blank" rel="noopener noreferrer" className="footerBottomSocialSVGContainer">
                                <img src={facebookvector} alt="" className="footerBottomSocialSVG" />
                            </Link>
                        </div>
                        {/* <div className="footerBottomSocialLinkContainer">
                            <Link to="https://www.facebook.com/InstaSafe" target="_blank" rel="noopener noreferrer" className="footerBottomSocialSVGContainer">
                                <img src={instagramvector} alt="" className="footerBottomSocialSVG" />
                            </Link>
                        </div> */}
                        <div className="footerBottomSocialLinkContainer">
                            <Link to="https://twitter.com/InstaSafe" target="_blank" rel="noopener noreferrer" className="footerBottomSocialSVGContainer">
                                <img src={twittervector} alt="" className="footerBottomSocialSVG" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
