import React from 'react'
import { Link } from 'react-router-dom'
import './content.css'
import programsScreenshot from '../../public/programsScreenshot.png'
import icon1 from '../../public/icon1.png'
import icon2 from '../../public/icon2.png'
import icon3 from '../../public/icon3.png'

export default function Programs() {
    return (
        <div className='navtabcontentContainer'>
            <div className="contentBody">
                <div className="contentList">
                    <div className="contentListItem">
                        <div className="contentItemIcon">
                            <img src={icon1} alt="" className="contentItemPNG" />
                        </div>
                        <div className="contentItemBody">
                            <div className="contentItemBodyHeading">Public Program</div>
                            <div className="contentItemBodyDescWrapper">
                                <div className="contentItemBodyDesc">Manage any project from start to finish with highly
                                    customizable views that make project planning a
                                    breeze.</div>
                            </div>
                        </div>
                    </div>
                    <div className="contentListItem">
                        <div className="contentItemIcon">
                            <img src={icon2} alt="" className="contentItemPNG" />
                        </div>
                        <div className="contentItemBody">
                            <div className="contentItemBodyHeading">Private Program</div>
                            <div className="contentItemBodyDescWrapper">
                                <div className="contentItemBodyDesc">Work with your team in real-time with Chat, assign
                                    comments for action items, and never miss a beat
                                    with notifications that bring everything in one
                                    place.</div>
                            </div>
                        </div>
                    </div>
                    <div className="contentListItem">
                        <div className="contentItemIcon">
                            <img src={icon3} alt="" className="contentItemPNG" />
                        </div>
                        <div className="contentItemBody">
                            <div className="contentItemBodyHeading">Program Details</div>
                            <div className="contentItemBodyDescWrapper">
                                <div className="contentItemBodyDesc">Add visual widgets for team members, tasks,
                                    sprints, time tracking, statuses, docs, embeds, and
                                    more.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="https://app.safehats.com/signup" target="_blank" rel="noopener noreferrer" className="contentButton">
                    <div className="contentButtonText">Join Now</div>
                </Link>
            </div>
            <div className="contentScreenshotContainer">
                <img src={programsScreenshot} alt="" className="contentScreenshot" />
            </div>
        </div>
    )
}
