import React from 'react';
import { Link } from 'react-router-dom';
import './infosection.css';
import framevector from '../../public/Framevector.png'

function Infosection() {
    return (
        <div className="sectionWrapper">
            <div className="whiteBox">
                <div className="infoTextContainer">
                    <div className="infoHeadingCotainer">
                        <div className="infoHeading">SafeHats Bug Bounty</div>
                    </div>
                    <div className="infoDescriptionContainer" >
                        <div className="infoDescription" >The SafeHats bug bounty program is an extension of your security setup. Designed for enterprises, the program taps into a vast pool of highly skilled and carefully vetted security researchers and ethical hackers to comprehensively test your application’s security. It also provides your customers with comprehensive protection.</div>
                    </div>
                    <Link to="https://app.safehats.com/" target="_blank" rel="noopener noreferrer" className="infoButtonContainer" >
                        <div className="infoButton" >
                            <Link to="https://app.safehats.com/" target="_blank" rel="noopener noreferrer" className="infoButtonText" >Try Safehats</Link>
                        </div>
                    </Link>
                </div>
                <div className="infoImageContainer">
                    <img className="infoImage" src={framevector} alt="Logo" />
                </div>
            </div>
        </div>
    );
}

export default Infosection; 