import React from 'react'
import './pinkherosection.css'
import pbicon1 from '../../public/pbicon1.png'
import pbicon2 from '../../public/pbicon2.png'
import pbicon3 from '../../public/pbicon3.png'
import pinkImage from '../../public/pinkImage.png'

export default function Pinkherosection() {
    return (
        <div className='sectionWrapper'>
            <div className='pinkBox'>
                <div className='contentContainer'>
                    <div className="row">
                        <div className="subHeading">
                            <div className="subHeadingText">JOIN US</div>
                        </div>
                        <div className="heading">
                            <div className="headingText">Start with safehats in 5 steps</div>
                        </div>
                    </div>
                    <div className="rowBottom">
                        <div className="textColumn">
                            <div className="listContainer">
                                <div className="listItem">
                                    <img src={pbicon1} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading">1. Create your account</div>
                                        <div className="listBody">Register and tell us about the product you want tested,
                                            and you are good to go. We will help you through the entire
                                            process of defining scope, setting up bounty and guide you
                                            in launching a managed vulnerability disclosure program.</div>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <img src={pbicon2} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading">2. Testing the program</div>
                                        <div className="listBody">We deploy a pool of trusted researchers, based upon your
                                            requirements to detect vulnerabilities in your application.
                                            Our hackers go way beyond to detect vulnerabilities that
                                            scanner can’t find. Findings are reported in real time.</div>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <img src={pbicon2} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading">3. Report the bugs </div>
                                        <div className="listBody">All bugs are assessed, validated and classified according
                                            to criticality, severity by our internal triage team. A plan of action
                                            is also recommended to help you manage the issues reported.</div>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <img src={pbicon2} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading">4. Fixing the bugs </div>
                                        <div className="listBody">Your internal development team can act upon the report and
                                            recommended plan of action to mitigate the discovered vulnerabilities.</div>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <img src={pbicon3} alt="" className="listIcon" />
                                    <div className="listItemRight">
                                        <div className="listHeading"> 5. Rewards</div>
                                        <div className="listBody">Reward the hackers and keep them incentivized to carry on the
                                            good work and keep looking for more bugs.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="imageColumn">
                            <img src={pinkImage} alt="" className="pinkBoxImage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
