import React from 'react';
import './listdesc.css';
import shicon1 from '../../public/shicon1.png'
import shicon2 from '../../public/shicon2.png'
import shicon3 from '../../public/shicon3.png'
import shicon4 from '../../public/shicon4.png'
import shicon5 from '../../public/shicon5.png'

function Communitypartner() {
    return (
        <div className="sectionWrapper">
            <div className="columnwhiteBox">
                <div className="ldheadingContainer">
                    <div className="ldheading"> Why Partner with Safehats?</div>
                </div>
                <div className="ldlistContainer">
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon1} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Networking Solutions
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon2} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Economic Upgradation
                            </div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon3} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Innovative Solution</div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon4} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Customer Friendly</div>
                        </div>
                    </div>
                    <div className="ldlist">
                        <div className="ldlistIconContainer">
                            <div className="ldlistIcon">
                                <img src={shicon5} alt="" className="ldlistSVG" />
                            </div>
                        </div>
                        <div className="ldlistTextContainer">
                            <div className="ldlistHeading">Higher Partner Margins
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Communitypartner; 